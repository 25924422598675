import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../components/layout"

const Vinylizr = () => {
  const data = useStaticQuery(graphql`
  {
    file( relativePath: {  eq: "vinylizr/README.md" } ) {
      childMarkdownRemark {
        html
      }
    }
  }
  `)

  const markdownHTML = data.file.childMarkdownRemark.html

  return (    
      <Layout pageTitle="Vinylizr"> 
        <div      
          className="markdownHTML-container"      
          dangerouslySetInnerHTML={{ __html: markdownHTML }}
        />
      </Layout>    
  )
}

export default Vinylizr